import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const StateExplorer = () => {
    return (
      <main>
        <Container>
          <Row>
            <Col>
              <p>This is some text in a container</p>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
export default StateExplorer